import React from 'react';
import PropTypes from 'prop-types';

import Action from '../Action';
import usePopup from '../../../../hooks/use-popup';
import Popup from '../Popup/Popup';
import RecallForm from './RecallForm';
import BecomePartner from '../BecomePartner';
import IconSvg from '../../../../common/javascript/components/IconSvg/IconSvg';

import { whatsappLink, telegramLink } from '../../../../helpers/messengers';

import * as styles from './ContactVariants.module.scss';

import Call from '../../../../../icons/call.svg';
import ReCall from '../../../../../icons/recall-lg.svg';
import Whatsapp from '../../../../../icons/whatsapp-new.svg';
import Telegram from '../../../../../icons/telegram.svg';
import Email from '../../../../../icons/email.svg';
import Instagram from '../../../../../icons/instagram-lg.svg';
import Vk from '../../../../../icons/vk-new.svg';
import BecomePartnerIcon from '../../../../../icons/partner2.svg';
import useAuth from '../../../../hooks/use-auth';

const contactItem = (contact, openRecallForm, openBecomePartnerForm) => (
  <div key={`contact_${contact.email}`} className={styles.ContactVariants}>
    {contact.phone && (
      <Action
        href={`tel:${contact.phone}`}
        target='_blank'
        themes={['Button', 'ButtonAttention']}
        className={styles.ContactVariants__button}
      >
        <IconSvg icon={Call} />
        <span>{contact.phone}</span>
      </Action>
    )}

    <Action
      to='/'
      onClick={openRecallForm}
      themes={['Button', 'ButtonSecondary', 'Fullwidth']}
      className={styles.ContactVariants__button}
    >
      <IconSvg icon={ReCall} className={styles.ContactVariants__recall} />
      <span>Свяжитесь со мной</span>
    </Action>

    {contact.messengers.whatsapp && (
      <Action
        href={whatsappLink(contact.messengers.whatsapp)}
        target='_blank'
        themes={['Button', 'ButtonSecondary']}
        className={styles.ContactVariants__button}
      >
        <IconSvg icon={Whatsapp} />
        <span>Написать в WhatsApp</span>
      </Action>
    )}

    {contact.messengers.telegram && (
      <Action
        href={telegramLink(contact.messengers.telegram)}
        target='_blank'
        themes={['Button', 'ButtonSecondary']}
        className={styles.ContactVariants__button}
      >
        <IconSvg icon={Telegram} />
        <span>Написать в Telegram</span>
      </Action>
    )}

    {contact.email && (
      <Action
        href={`mailto:${contact.email}`}
        target='_blank'
        themes={['Button', 'ButtonSecondary']}
        className={styles.ContactVariants__button}
      >
        <IconSvg icon={Email} />
        <span>Написать E-mail</span>
      </Action>
    )}

    {true && (
      <Action
        to='/'
        onClick={openBecomePartnerForm}
        themes={['Button', 'ButtonSecondary', 'Fullwidth']}
        className={styles.ContactVariants__button}
      >
        <IconSvg icon={BecomePartnerIcon} />
        <span>Стать партнером</span>
      </Action>
    )}

    <div className={styles.ContactVariants__socials}>
      {contact.messengers.instagram && (
        <Action
          href={contact.messengers.instagram}
          target='_blank'
          themes={['Button', 'ButtonSecondary']}
          className={styles.ContactVariants__button}
        >
          <IconSvg icon={Instagram} className={styles.ContactVariants__instagram} />
        </Action>
      )}

      {contact.messengers.vk && (
        <Action
          href={contact.messengers.vk}
          target='_blank'
          themes={['Button', 'ButtonSecondary']}
          className={styles.ContactVariants__button}
        >
          <IconSvg icon={Vk} />
        </Action>
      )}
    </div>
  </div>
);

const ContactVariants = ({ contacts }) => {
  const [isRecallFormOpen, openRecallForm, closeRecallForm] = usePopup();
  const [isBecomePartnerFormOpen, openBecomePartnerForm, closeBecomePartnerForm] = usePopup();
  const { user } = useAuth();

  return (
    <>
      {contacts.forTours?.map(contact => contactItem(contact, openRecallForm, openBecomePartnerForm))}

      <Popup
        show={isRecallFormOpen}
        onClose={closeRecallForm}
        escapeClose={false}
        outsideClose={false}
        variant='small'
      >
        <RecallForm
          onClose={closeRecallForm}
          recallPhone={user.phone}
          recallName={user.name}
        />
      </Popup>

      <Popup
        show={isBecomePartnerFormOpen}
        onClose={closeBecomePartnerForm}
        variant='medium'
      >
        <BecomePartner contact={contacts.forPartners} />
      </Popup>
    </>
  );
};

ContactVariants.propTypes = {
  contacts: PropTypes.shape({
    forTours: PropTypes.arrayOf(PropTypes.shape({
      phone: PropTypes.string,
      messengers: PropTypes.shape({
        whatsapp: PropTypes.string,
        telegram: PropTypes.string
      }),
      email: PropTypes.string,
      vkUrl: PropTypes.string,
      instagramUrl: PropTypes.string
    })),
    forPartners: PropTypes.shape({
      phone: PropTypes.string,
      messengers: PropTypes.shape({
        whatsapp: PropTypes.string,
        telegram: PropTypes.string
      }),
      email: PropTypes.string,
      vkUrl: PropTypes.string,
      instagramUrl: PropTypes.string
    })
  }).isRequired
};

export default ContactVariants;
